exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-output-tsx": () => import("./../../../src/pages/output.tsx" /* webpackChunkName: "component---src-pages-output-tsx" */),
  "component---src-pages-overview-tsx": () => import("./../../../src/pages/overview.tsx" /* webpackChunkName: "component---src-pages-overview-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-research-team-tsx": () => import("./../../../src/pages/research-team.tsx" /* webpackChunkName: "component---src-pages-research-team-tsx" */),
  "component---src-pages-work-packages-tsx": () => import("./../../../src/pages/work-packages.tsx" /* webpackChunkName: "component---src-pages-work-packages-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

